import { ReactNode } from 'react';
import { TranslationObject } from './translations';

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    _tmr: any;
  }
}

export type Locale = string | undefined;
export type Lang = 'ru' | 'en';

export enum Sizes {
  Extra = 'extra',
  Big = 'big',
  Small = 'small',
}

export enum Variants {
  Outline = 'outline',
  Filled = 'filled',
  Ghost = 'ghost',
}

export enum Palettes {
  Primary = 'primary',
  Secondary = 'secondary',
}

export type ProviderProps = {
  children: ReactNode;
};

export type UserDataType = {
  id: number;
  name: string;
  lastName: string;
  secondName: string | null;
  mindboxId: string | null;
  birthday: string;
  phone: string;
  phoneConfirmed: boolean;
  email: string;
  emailConfirmed: boolean;
  lastDateChangeBirthday: string;
  xmlId: string;
  persons: { name: string; birthday: string; lastDateChangeBirthday: string }[];
} | null;

export type InitialState = {
  showSidebar: boolean;
  searchActive: boolean;
  userData: UserDataType;
  successReg: boolean;
};

export enum FETCH_METHODS {
  GET = 'GET',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export type SearchResult = {
  id: number;
  name: string;
  score: number;
  section: string;
  text: string;
  url: string;
};

export type EntertainmentType = {
  id: number;
  name: string;
  code: string;
  location: {
    id: number;
    code: string;
    name: string;
    picture: string;
  };
  picture: string;
  text: string;
  tag: string;
  closed: boolean;
  soon: boolean;
  techClosed: boolean;
  type: string;
  pageMissing: boolean;
  link: string;
  alert: {
    name: string;
    icon: string;
  };
};

export type AllItemsListType = {
  list: ObjectType[];
  pagination: {
    page: number;
    countPages: number;
    countElements: number;
  };
};

export type ObjectType = {
  id: number;
  name: string;
  code: string;
  picture: string;
  pictureMob: string;
  tag: string;
  button: string;
  info: string;
  date: string;
  mode: string;
  link: string;
  text: string;
  buttonLink: string;
  buttonDisabled: boolean;
  pageMissing: boolean;
  vkId: string;
  adriverId: string;
  techClosed: boolean;
  alert: {
    name: string;
    icon: string;
  };
  colorStyles: string;
  styles: string;
};

export type BannerType = {
  id: number;
  name: string;
  picture: string;
  pictureMob: string;
  tag: string;
  button: string;
  link: string;
  info: string;
  video: string;
  buttonLink: string;
  videoMob: string;
};

export type EventType = {
  id: number;
  code: string;
  name: string;
  dateFrom: string;
  dateTo: string;
  dateText: string;
  picture: string;
  text: string;
  info: string;
  button: string;
  timeFrom: string;
  timeTo: string;
  tag: string;
  closed: boolean;
  needRegistration: boolean;
  buttonLink: string;
  pageMissing: boolean;
};

export type ServiceType = {
  code: string;
  id: number;
  name: string;
  picture: string;
  text: string;
  tag: string;
  button: string;
  mode: string;
};

export type SceneType = {
  name: string;
  text: string;
  gallery: string[];
  galleryMob: string[];
  code: string;
  map: string;
  events: EventType[];
  location: {
    picture: string;
    file: string;
    pictureMob: string;
  };
};

export type LocationType = {
  id: number;
  code: string;
  name: string;
  type: string;
  picture: string;
  gallery: string[];
  galleryMob: string[];
  text: string;
  entertainment: EntertainmentType[];
  trades: TradeType[];
  zoneLocation: {
    picture: string;
    file: string;
    pictureMob: string;
  };
  pageMissing: boolean;
  blocks: string[];
  ourObject: ObjectType;
};

export type AdType = {
  id: number;
  name: string;
  text: string;
  picture: string;
  link: string;
};

export type BookingType = {
  id: string;
  code: string;
  name: string;
  gallery: string[];
  galleryMob: string[];
  text: string;
  services: ServiceType[];
  who: {
    id: number;
    code: string;
    name: string;
  }[];
  examples: string[];
  ourObject: ObjectType;
};

export type BookingDateType = {
  id: number;
  date: Date;
  serviceId: number;
  parentId: number;
  time: string;
  seats: number;
};

export type ParamsType = {
  id: number;
  name: string;
  value: string;
  icon: string;
  help: string;
  link: string;
};

export type PromotionType = {
  id: number;
  name: string;
  text: string;
  logo: string;
  period: string;
  picture: string;
  link: string;
};

export type SpecialOfferType = {
  id: number;
  link: string;
  name: string;
  period: string;
  picture: string;
  tag: string;
  text: string;
};

export type TradeType = {
  id: number;
  code: string;
  type: string;
  typeName: string;
  name: string;
  picture: string;
  logo: string;
  gallery: string[];
  galleryMob: string[];
  text: string;
  foodCourt: TradeType[];
  rests: TradeType[];
  shops: TradeType[];
  location: {
    file: string;
    picture: string;
    pictureMob: string;
  };
  promotions: PromotionType[];
  pageMissing: boolean;
  link?: string;
  alert: {
    name: string;
    icon: string;
  };
  events: EventType[];
  entertainments: EntertainmentType[];
};

export type FatherMorozAdminRowType = {
  id: number;
  date: string;
  ticket: string;
  name: string;
  birthday: string;
  giftGiven: boolean;
};

export type NewsType = {
  id: number;
  code: string;
  name: string;
  picture: string;
  date: string;
  text: string;
  pageMissing: boolean;
};

export type TicketsType = {
  id: number;
  name: string;
  text: string;
  help: string;
  price: number;
  numberOfPersons: number;
  button: string;
  link: string;
  size?: 'medium' | 'big';
};

export type Placement = {
  link: string;
  code: string;
  name: string;
  picture: string;
  text: string;
  big: boolean;
  pageMissing: boolean;
};

export type ObjectItemType = {
  id: 10;
  code: string;
  name: string;
  picture: string;
  text: string;
  priceFrom: string;
  gallery: string[];
  galleryMob: string[];
  displayEntertainmentList: boolean;
  entertainments: EntertainmentType[];
  tickets: TicketsType[];
  trades: TradeType[];
  shops: TradeType[];
  rests: TradeType[];
  locations: LocationType[];
  location: {
    picture: string;
    file: string;
    pictureMob: string;
  };
  placements: Placement[];
  promotions: PromotionType[];
  thingsToDo: PromotionType[];
  events: EventType[];
  summerCinema: Placement[];
  restZone: string[];
  playgrounds: string[];
  summerTrade: TradeType[];
  alert: {
    name: string;
    icon: string;
  };
  blocks: string[];
  styles: string;
  colorStyles: string;
};

type VacancyBannerType = {
  id: number;
  name: string;
  picture: string;
  pictureMob: string;
  tag: string;
  button: string;
  link: string;
} | null;

export type MainPageDataType = {
  banners: BannerType[];
  objects: ObjectType[];
  entertainments: EntertainmentType[];
  events: EventType[];
  ads: AdType[];
  trades: TradeType[];
  news: NewsType[];
  meta: MetaType;
  vacancyBanner: VacancyBannerType;
};

export type MenuItem = {
  id: number;
  name: string;
  code: string;
  value: string;
  action: string;
  parentId: number;
  items: MenuItem[];
  icon: string;
  onlyMobile: boolean;
};

export type FooterLinkType = {
  name: string;
  link: string;
};

export type ModeType = {
  id: number;
  name: string;
  mode: string;
  restriction: string;
};

export type SocialType = {
  code: string;
  link: string;
};

export type SettingsType = {
  menu: MenuItem[];
  menuFooter: FooterLinkType[];
  mode: ModeType[];
  socials: SocialType[];
  disclaimer: string;
  copyright: string;
  address: string;
  links: BuyButtonLink[];
  phone: string;
  translations: TranslationObject;
  showButtonQrCode: boolean;
};

export type MetaType = {
  [key: string]: string | undefined;
};

export type BuyButtonLink = {
  path: string;
  buyPath: string;
  vkId: string;
  adriverId: string;
};

export type PartyPlace = {
  id: number;
  name: string;
  text: string;
  gallery: string[];
  picture: string;
};

export type PartyPlaceLocation = {
  id: number;
  name: string;
  placeId: number;
  text: string;
  gallery: string[];
  picture: string;
  floor: string;
};

export type LunchType = {
  id: number;
  name: string;
  picture: string;
  price: number;
  text: string;
  gallery: string[];
};

export type QuestType = {
  id: number;
  name: string;
  picture: string;
  text: string;
  gallery: string[];
  price1Hour: number;
  price3Hour: number;
  price5Hour: number;
};

export type PackageType = {
  id: number;
  name: string;
  text: string;
  price: number;
  picture: string;
  options: PackageOptionType[];
  customizableOptions: boolean;
  gallery: string[];
};

export type PackageOptionType = {
  id: number;
  name: string;
  sectionId: number;
  price: number;
  picture: string;
  count: number;
};

export type AddOptionType = {
  id: number;
  name: string;
  text: string;
  price: number;
  picture: string;
  gallery: string[];
};

export type FatherFrostDateType = {
  id: number;
  date: string;
  count: number;
};

export type FatherMorozType = {
  item: {
    id: number;
    name: string;
    text: string;
    gallery: string[];
    galleryMob: string[];
  };
  list: FatherFrostDateType[];
  meta: MetaType;
  code: number;
};

export type ChipType = {
  code: string;
  icon: {
    link: string;
  };
  iconActive: {
    link: string;
  };
  id: string;
  name: string;
};

export type TabVariant = {
  label: string;
  value: string;
};

export type DesignVariantType = {
  id: number;
  name: string;
  picture: string;
  types: number[];
};

export type CertificateCostsType = {
  id: number;
  cost: number;
  canBuy: string;
  typeId: number;
};
